import React from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

function SEO({ title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = data.site.siteMetadata.description;
        const fullTitle = title
          ? `${title} · ${data.site.siteMetadata.title}`
          : `${data.site.siteMetadata.title}`;
        const lang = 'en';
        const keywords = [
          'illustration',
          'graphic design',
          'designer',
          'singapore'
        ];

        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={fullTitle}
            meta={[
              {
                name: `description`,
                content: metaDescription
              },
              {
                property: `og:title`,
                content: title
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                name: `twitter:card`,
                content: `summary`
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author
              },
              {
                name: `twitter:title`,
                content: title
              },
              {
                name: `twitter:description`,
                content: metaDescription
              }
            ].concat(
              keywords.length > 0
                ? {
                    name: `keywords`,
                    content: keywords.join(`, `)
                  }
                : []
            )}
          />
        );
      }}
    />
  );
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

export default SEO;
