import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import Image from 'gatsby-image';

class Header extends React.Component {
  render() {
    return (
      <StaticQuery
        query={headerQuery}
        render={data => (
          <header className="level is-mobile header">
            <div className="level-left">
              <Link to="/">
                <Image
                  className="level-item"
                  fixed={data.logo.childImageSharp.fixed}
                />
              </Link>
            </div>

            <nav className="level-right">
              <Link
                getProps={({ location }) => {
                  return location.pathname === '/' ||
                    location.pathname.startsWith('/work/')
                    ? { className: 'level-item nav-active' }
                    : { className: 'level-item' };
                }}
                to="/"
              >
                WORK
              </Link>

              <a
                className="level-item"
                href="https://pickypalette.bigcartel.com"
              >
                STORE
              </a>

              <Link
                className="level-item"
                activeClassName="nav-active"
                to="/about"
              >
                ABOUT
              </Link>
            </nav>
          </header>
        )}
      />
    );
  }
}

const headerQuery = graphql`
  query headerQuery {
    logo: file(name: { eq: "logo" }) {
      childImageSharp {
        fixed(width: 85, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default Header;
