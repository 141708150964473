import React from 'react';

import SEO from './SEO';
import Header from './Header';
import Footer from './Footer';
import '../styles/App.scss';

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="app">
        <SEO />

        <Header />

        <main>{children}</main>

        <Footer />
      </div>
    );
  }
}

export default Layout;
