import React from 'react';

class Footer extends React.Component {
  scrollToTop(e) {
    e.preventDefault();

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  render() {
    return (
      <footer className="footer">
        <a onClick={this.scrollToTop} href="#app">
          ↑↑↑
        </a>
      </footer>
    );
  }
}

export default Footer;
